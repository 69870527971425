import React, { useEffect, useState } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "./App.css";
import app from "./firebase.config";

function App() {
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationDesc, setNotificationDesc] = useState("");

    useEffect(() => {
        const requestPermission = () => {
            console.log("Requesting permission...");
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                    const messaging = getMessaging(app);
                    onMessage(messaging, (payload) => {
                        console.log("Message received. ", payload);
                        setNotificationTitle(payload.notification?.title || "");
                        setNotificationDesc(payload.notification?.body || "");
                    });

                    getToken(messaging, {
                        vapidKey:
                            "BF27fJZcFjZPuwVkX0gHJCpv7T3pSoLanIYyEMA6DEpkPdq7FhEwtrUem-6X0DteFmoONDiY8VuHCfuxso5_1ic",
                    })
                        .then((token) => {
                            console.log(token);
                        })
                        .catch((err) => console.log(err));
                }
            });
        };

        requestPermission();
    }, []);

    return (
        <>
            <p>Notification Title: {notificationTitle}</p>
            <p>Notification Desc: {notificationDesc}</p>
        </>
    );
}

export default App;
