import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyD1fGlwlnnWiZY7HZ8q8kNQT3Nx_ka0DU8",
    authDomain: "pwa-demo-853f8.firebaseapp.com",
    projectId: "pwa-demo-853f8",
    storageBucket: "pwa-demo-853f8.appspot.com",
    messagingSenderId: "1009863593330",
    appId: "1:1009863593330:web:49a00b6ea26a1ae384a1eb",
};

const app = initializeApp(firebaseConfig);

export default app;
